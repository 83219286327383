const React = require("react")
// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {

    if(prevLocation && (location.pathname !== prevLocation.pathname)){
        window.dataLayer && window.dataLayer.push({'event':'dealermasters-quick-load'});
    }
    const customEvent = new CustomEvent('dealermasters-quick-load', {
        detail: {
            location,
            prevLocation
        }
    });
    window.dispatchEvent(customEvent);
}
